import { Injectable, inject } from '@angular/core';
import { WA_WINDOW } from '@ng-web-apis/common';
import { BehaviorSubject, delay, takeWhile } from 'rxjs';
import { take } from 'rxjs/operators';
import { LazyLoadingScriptService } from './lazy-load-script.service';

@Injectable({
  providedIn: 'root',
})
export class GoogleService {
  private readonly lazyLoadingScript = inject(LazyLoadingScriptService);
  private readonly window = inject(WA_WINDOW);
  public google: any;
  public google$ = new BehaviorSubject<any>(null);
  public response$ = new BehaviorSubject<any>(null);

  init(GOOGLE_CLIENT_ID: string) {
    if (this.google) {
      this.google$.next(this.google);

      return;
    }

    this.lazyLoadingScript
      .loadScript('https://accounts.google.com/gsi/client')
      .pipe(
        take(1),
        delay(500),
        takeWhile(() => !this.google),
      )
      .subscribe(() => {
        this.google = (this.window as any)['google'];

        this.google?.accounts?.id?.initialize?.({
          client_id: GOOGLE_CLIENT_ID,
          cookiepolicy: 'single_host_origin',
          cancel_on_tap_outside: true,
          auto_select: false,
          auto_login: false,
          callback: (response: any) => {
            this.response$.next(response);
          },
        });

        this.google$.next(this.google);
      });
  }

  disableAutoSelect() {
    this.google?.accounts?.id?.disableAutoSelect?.();
  }

  cancel() {
    this.google?.accounts?.id?.cancel?.();
  }

  logout() {
    this.response$.next(null);
    this.disableAutoSelect();
  }
}
