import { Injectable, inject } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LazyLoadingScriptService {
  private readonly document = inject(DOCUMENT);
  _loadedLibraries: Record<string, ReplaySubject<null>> = {};

  loadScript(
    url: string,
    { crossOrigin } = { crossOrigin: false },
  ): Observable<any> {
    if (this._loadedLibraries[url]) {
      return this._loadedLibraries[url].asObservable();
    }

    this._loadedLibraries[url] = new ReplaySubject();

    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.async = true;
    if (crossOrigin !== false) {
      script.crossOrigin = typeof crossOrigin === 'string' ? crossOrigin : '';
    }
    script.onload = () => {
      this._loadedLibraries[url].next(null);
      this._loadedLibraries[url].complete();
    };

    this.document.body.appendChild(script);

    return this._loadedLibraries[url].asObservable();
  }
}
