import { MeEntity } from '@admefy/domain';
import { HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { Observable, Subject, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UAParser } from 'ua-parser-js';
import { RestService } from './rest.service';

/**
 * Se encarca de hacer las acciones contra la API
 * */
@Injectable({ providedIn: 'root' })
export class LoginService {
  private _http = inject(RestService);
  private subject: Subject<boolean> = new Subject<boolean>();

  public onLoginChange() {
    return this.subject;
  }

  public onLogin(): void {
    return this.subject.next(true);
  }

  public onLogout(): void {
    return this.subject.next(false);
  }

  public loginWithGoogle(
    token: string,
  ): Observable<{ token: string; user: MeEntity }> {
    return this._http
      .post(`/v1/auth/google`, { token })
      .pipe(map((res) => res.body?.data));
  }

  public login(body: {
    username: string;
    password: string;
  }): Observable<{ token: string; user: MeEntity }> {
    const parser = new UAParser();
    return from(FingerprintJS.load()).pipe(
      switchMap((fp) => fp.get()),
      switchMap((result) => {
        const results = parser.getResult();
        const browserData = results.os.name + ' ' + results.browser.name;

        return this._http
          .post(`/v1/auth`, {
            ...body,
            fingerprint: {
              fingerprint: result?.visitorId,
              tag: browserData,
            },
          })
          .pipe(map((res) => res.body?.data));
      }),
    );
  }

  public restorePassword(email: string, url: string = null) {
    const params = new HttpParams()
      .set('email', email)
      .set('url_fallback', url);
    return this._http.get(`/v1/pass-restore`, params);
  }

  public contactForm(body) {
    return this._http
      .post(`/v1/contact`, body)
      .pipe(map((res) => res.body?.data));
  }

  public reportForm(body) {
    return this._http
      .post(`/v1/report`, body)
      .pipe(map((res) => res.body?.data));
  }

  public setPassword(body) {
    return this._http.put(`/v1/pass-restore`, body);
  }
}
