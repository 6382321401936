import { IPQuery, Metadata } from '../types';

export function transformIpQueryResponse(response: IPQuery | null): Metadata {
  return {
    zip: response?.location?.zipcode,
    timezone: response?.location?.timezone,
    region: response?.location?.state,
    organization_name: response?.isp?.org,
    country_code: response?.location?.country_code,
    organization: response?.isp?.asn,
    latitude: response?.location?.latitude.toString(),
    longitude: response?.location?.longitude.toString(),
    ip: response?.ip,
    city: response?.location?.city,
    country: response?.location?.country,
    continent: response?.location?.country_code,
  };
}
