import dayjs, { PluginFunc } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc'; // dependent on utc plugin

import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';

export type DaysName = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';
const getDayOfWeekName: PluginFunc = (option, dayjsClass, dayjsFactory) => {
  dayjsClass.prototype['dayOfWeekName'] = function (): DaysName {
    const dayOfWeek = this.day();
    const daysName: DaysName[] = [
      'sun',
      'mon',
      'tue',
      'wed',
      'thu',
      'fri',
      'sat',
    ];
    return daysName[dayOfWeek];
  };
};

const eachDayOfInterval: PluginFunc = (option, dayjsClass, dayjsFactory) => {
  dayjsClass.prototype['isBetween'] = function (
    fromDate: dayjs.Dayjs,
    toDate: dayjs.Dayjs,
  ): boolean {
    return !this.isAfter(toDate) && !this.isBefore(fromDate);
  };

  dayjsClass.prototype['eachDayOfInterval'] = function (
    toDate: dayjs.Dayjs,
  ): dayjs.Dayjs[] {
    let from: dayjs.Dayjs = this;
    const interval = [];
    while (!from.isAfter(toDate)) {
      interval.push(from);
      from = from.add(1, 'day');
    }
    return interval;
  };
};

declare module 'dayjs' {
  interface Dayjs {
    eachDayOfInterval(toDate: dayjs.Dayjs): dayjs.Dayjs[];
    isBetween(fromDate: dayjs.Dayjs, toDate: dayjs.Dayjs): boolean;
    dayOfWeekName(): DaysName;
  }
}

dayjs.extend(utc);
// dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(eachDayOfInterval);
dayjs.extend(getDayOfWeekName);
dayjs.locale('es');

export default dayjs;
