import { TranslocoHttpLoader } from '@/transloco-loader';
import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import {
  APP_ID,
  ApplicationConfig,
  enableProdMode,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import {
  MAT_DATE_LOCALE,
  provideNativeDateAdapter,
} from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import {
  provideClientHydration,
  withNoHttpTransferCache,
} from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  GuardsCheckEnd,
  NavigationEnd,
  provideRouter,
  withComponentInputBinding,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withRouterConfig,
} from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { provideTransloco } from '@jsverse/transloco';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import player from 'lottie-web';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import {
  provideCacheableAnimationLoader,
  provideLottieOptions,
} from 'ngx-lottie';
import { provideNgProgressRouter } from 'ngx-progressbar/router';
import { provideShareButtonsOptions } from 'ngx-sharebuttons';
import { shareIcons } from 'ngx-sharebuttons/icons';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { APP_ROUTES } from './app.routes';
import { fingerPrintInterceptor } from './core/interceptors/fingerprint.interceptor';
import { TranslationHelpPipe } from './core/pipes/translation-help';
import { SocketModule } from './core/services/socket';
import { SpinnerModule } from './core/services/spinner';
import { HOST_URL } from './core/tokens/host-urls';

if (!isDevMode()) {
  enableProdMode();
}

function playerFactory() {
  return player;
}

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: APP_ID,
      useValue: 'serverApp',
    },
    {
      provide: HOST_URL,
      useValue: environment.baseUrl,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    provideShareButtonsOptions(shareIcons()),
    provideNativeDateAdapter(),
    provideClientHydration(withNoHttpTransferCache()),
    provideAnimationsAsync(),
    provideHttpClient(withFetch(), withInterceptors([fingerPrintInterceptor])),
    provideCharts(withDefaultRegisterables()),
    provideTransloco({
      config: {
        availableLangs: ['en', 'es'],
        defaultLang: 'en',
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideRouter(
      APP_ROUTES,
      withComponentInputBinding(),
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
        anchorScrolling: 'enabled',
      }),
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
        onSameUrlNavigation: 'reload',
      }),
    ),
    provideServiceWorker('/service-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    importProvidersFrom(
      MatDialogModule,
      SweetAlert2Module.forRoot(),
      SpinnerModule.forRoot(),
      ToastrModule.forRoot({
        timeOut: 3000,
        preventDuplicates: true,
      }),
      SocketModule.forRoot({
        url: environment.wsAPIUrl,
        options: {
          forceNew: false,
          transports: ['websocket'],
          reconnectionAttempts: Infinity,
          autoConnect: true,
        },
      }),
    ),
    provideLottieOptions({
      player: playerFactory,
      useWebWorker: false,
    }),
    provideCacheableAnimationLoader(),
    provideNgProgressRouter({
      startEvents: [GuardsCheckEnd],
      completeEvents: [NavigationEnd],
      minDuration: 1000,
    }),
    TranslationHelpPipe,
  ],
};
