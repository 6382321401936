import { Pipe, PipeTransform, inject } from '@angular/core';
import { GlobalStateService } from '../services/globalstate.service';

@Pipe({
  name: 'translationHelp',
})
export class TranslationHelpPipe implements PipeTransform {
  private gs = inject(GlobalStateService);

  transform(
    translations: {
      lang: string;
      title: string;
      description: string;
    }[],
  ): string {
    const lang = this.gs.get('lang');

    for (const translation of translations) {
      if (translation.lang === lang) {
        return translation.title || translation.description;
      }
    }

    return translations?.[0]?.title || translations?.[0]?.description;
  }
}
