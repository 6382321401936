import type {
  CreateDisplayDto,
  CursorPaginationOptions,
  CursorPaginationResult,
  DisplayEntity,
  DisplayFlattenEntity,
  DisplayLocationHistoryEntity,
  SearchDisplaysQueryDto,
  UpdateDisplayDto,
} from '@admefy/domain';
import { Injectable, inject } from '@angular/core';
import { serialize } from 'object-to-formdata';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import type { Stripe } from 'stripe';
import { Cacheable } from 'ts-cacheable';
import { MetadataService } from './metadata';
import { RestService, type HttpParamsType } from './rest.service';

/**
 * Se encarca de hacer las acciones contra la API
 * */
@Injectable({ providedIn: 'root' })
export class DisplayService {
  private readonly _http = inject(RestService);
  private readonly _metadata = inject(MetadataService);

  // Crear nuevo display
  public createDisplay<T extends { flatten: boolean }>(
    body: Omit<CreateDisplayDto, 'owner'>,
    options?: T,
  ): Observable<
    | (T['flatten'] extends true ? DisplayFlattenEntity : DisplayEntity)
    | {
        requires_action: boolean;
        payment_intent_client_secret: string;
        subscriptionId: string;
      }
  > {
    return this._metadata.getGeoData().pipe(
      switchMap((metadata) => {
        body.metadata = {
          location: {
            type: 'Point',
            coordinates: [
              Number(metadata?.longitude) ?? 0,
              Number(metadata?.latitude) ?? 0,
            ],
          },
          ...body.metadata,
          ...metadata,
        };

        const data = serialize(body, {
          indices: true,
        });

        return this._http
          .postFormData(`/v1/display`, data, {
            params: { flatten: !!options?.flatten },
          })
          .pipe(map((res) => res.body?.data));
      }),
    );
  }

  public searchLocationsByDisplayId(
    displayId: string,
    options: CursorPaginationOptions,
  ): Observable<CursorPaginationResult<DisplayLocationHistoryEntity>> {
    return this._http
      .get(`/v1/display-locations/${displayId}`, options as HttpParamsType)
      .pipe(map((res) => res.body?.data));
  }

  // Checkar Allowed users para ver grabaciones se setean en el display de ahí obtenemos el owner.
  public checkAllowedUserByOwner(ownerId: string): Observable<true> {
    return this._http
      .post(`/v1/display/checkalloweduser`, { ownerId })
      .pipe(map((res) => res.body?.data));
  }

  public checkIsMyDisplay(displayId: string): Observable<true> {
    return this._http
      .get(`/v1/display/${displayId}/is-my`)
      .pipe(map((res) => res.body?.data));
  }

  public checkImProviderOfDisplay(displayId: string): Observable<true> {
    return this._http
      .get(`/v1/display/${displayId}/is-provider`)
      .pipe(map((res) => res.body?.data));
  }

  public canAccessToDisplay(
    displayId: string,
  ): Observable<{ canAccess: boolean; mediaId?: string }> {
    return this._http
      .get(`/v1/display/${displayId}/canaccess`)
      .pipe(map((res) => res.body?.data));
  }

  // Refresh Display
  public displayRefresh(displayId: string): Observable<void> {
    return this._http
      .get(`/v1/display/${displayId}/refresh`)
      .pipe(map((res) => res.body?.data));
  }

  // Unlink Display
  public unlinkDisplay(displayId: string): Observable<void> {
    return this._http
      .get(`/v1/display/${displayId}/unlink`)
      .pipe(map((res) => res.body?.data));
  }

  // Obtener ip del display mediante sockets.
  public displayIp(displayId: string): Observable<void> {
    return this._http
      .get(`/v1/display/${displayId}/getip`)
      .pipe(map((res) => res.body?.data));
  }

  // Obtener version del display mediante sockets.
  public displayVersion(displayId: string): Observable<void> {
    return this._http
      .get(`/v1/display/${displayId}/getversion`)
      .pipe(map((res) => res.body?.data));
  }

  // Peticion de verificación de token
  public onScanQR(body: {
    token: string;
    coords?: {
      latitude: number;
      longitude: number;
    };
  }): Observable<{
    token: string;
  }> {
    return this._http
      .post(`/v1/display/qr/scan`, body)
      .pipe(map((res) => res.body?.data));
  }

  // Obtencion de los displays conectados
  public getDisplaysConnected<
    T extends {
      flatten: boolean;
    },
  >(
    options?: T,
    cache?: boolean | number,
  ): Observable<
    (T['flatten'] extends true ? DisplayFlattenEntity : DisplayEntity)[]
  > {
    return this._http
      .get(`/v1/display/connected`, { flatten: !!options?.flatten }, cache)
      .pipe(map((res) => res.body?.data));
  }

  @Cacheable()
  public searchDisplays<T extends SearchDisplaysQueryDto>(
    query: T,
  ): Observable<
    CursorPaginationResult<
      T['flatten'] extends true ? DisplayFlattenEntity : DisplayEntity
    >
  > {
    return this._http
      .get(`/v1/display`, query as unknown as Record<string, string>, false)
      .pipe(map((res) => res.body?.data));
  }

  public searchMyDisplays<T extends Omit<SearchDisplaysQueryDto, 'owners'>>(
    query?: T,
    cache: boolean | number = false,
  ): Observable<
    CursorPaginationResult<
      T['flatten'] extends true ? DisplayFlattenEntity : DisplayEntity
    >
  > {
    return this._http
      .get(
        `/v1/display/user/me`,
        query as unknown as Record<string, string>,
        cache,
      )
      .pipe(map((res) => res.body?.data));
  }

  public getDisplaysProviderByMe<
    T extends {
      flatten: boolean;
    },
  >(
    options?: T,
    cache: boolean | number = false,
  ): Observable<
    (T['flatten'] extends true ? DisplayFlattenEntity : DisplayEntity)[]
  > {
    return this._http
      .get(
        `/v1/display/provider/me`,
        {
          flatten: !!options?.flatten,
        },
        cache,
      )
      .pipe(map((res) => res.body?.data));
  }

  public getDisplayRaised(displayId: string): Observable<any> {
    return this._http
      .get(`/v1/display/${displayId}/raised`)
      .pipe(map((res) => res.body?.data));
  }

  // Obtener un display por id del mismo
  public getDisplayById<
    T extends {
      flatten?: boolean;
      cache?: boolean | number;
    },
  >(
    displayId: string,
    options?: T,
  ): Observable<
    T['flatten'] extends true ? DisplayFlattenEntity : DisplayEntity
  > {
    return this._http
      .get(
        `/v1/display/${displayId}`,
        { flatten: !!options?.flatten },
        options?.cache,
      )
      .pipe(map((res) => res.body?.data));
  }

  // Genera el id corto a partir del token.
  public generateDisplayToken(displayId: string): Observable<{
    id: string;
  }> {
    return this._http
      .get(`/v1/display/${displayId}/link`)
      .pipe(map((res) => res.body?.data));
  }

  // Borrar un display
  public deleteDisplay(displayId: string): Observable<void> {
    return this._http
      .delete(`/v1/display/${displayId}`)
      .pipe(map((res) => res.body?.data));
  }

  // Actualizar display
  public updateDisplayById<
    T extends {
      flatten: boolean;
    },
  >(
    displayId: string,
    body: UpdateDisplayDto,
    options?: T,
  ): Observable<
    | (T['flatten'] extends true ? DisplayFlattenEntity : DisplayEntity)
    | {
        requires_action: boolean;
        payment_intent_client_secret: string;
        subscriptionId: string;
      }
  > {
    const data = serialize(body, {
      indices: true,
    });
    return this._http
      .putFormData(`/v1/display/${displayId}`, data, {
        params: { flatten: !!options?.flatten },
      })
      .pipe(map((res) => res.body?.data));
  }

  // Actualizar el numero de vistas del display
  public updateNumOfViews(
    displayId: string,
    body: any,
  ): Observable<DisplayEntity> {
    return this._http
      .put(`/v1/display/${displayId}/views`, body)
      .pipe(map((res) => res.body?.data));
  }

  // Banear usuario en el canal
  public userBan(displayId: string, userId: string): Observable<void> {
    return this._http
      .put(`/v1/display-moderators/${displayId}/ban`, { userId })
      .pipe(map((res) => res.body?.data));
  }

  // Mutear usuario en el canal
  public userMuted(displayId: string, userId: string): Observable<void> {
    return this._http
      .put(`/v1/display-moderators/${displayId}/muted`, { userId })
      .pipe(map((res) => res.body?.data));
  }

  // Desbanear usuario en el canal
  public userUnban(displayId: string, userId: string): Observable<void> {
    return this._http
      .put(`/v1/display-moderators/${displayId}/unban`, { userId })
      .pipe(map((res) => res.body?.data));
  }

  // Desmutear usuario en el canal
  public userUnmuted(displayId: string, userId: string): Observable<void> {
    return this._http
      .put(`/v1/display-moderators/${displayId}/unmuted`, { userId })
      .pipe(map((res) => res.body?.data));
  }

  // Actualiza el screensaver de la pantalla
  public setCustomScreenSaver(
    displayId: string,
    { url, screenSaverDuration }: { url: string; screenSaverDuration?: number },
  ): Observable<void> {
    return this._http
      .put(`/v1/display/${displayId}/screensaver`, {
        url,
        screenSaverDuration,
      })
      .pipe(map((res) => res.body?.data));
  }

  // Obtenemos el screensaver de la pantalla
  public getCustomScreenSaver(displayId: string): Observable<{
    url: string;
    duration: number;
    subscription: any;
  }> {
    return this._http
      .get(`/v1/display/${displayId}/screensaver`)
      .pipe(map((res) => res.body?.data));
  }

  // Obtenemos el screensaver de la pantalla
  public getCustomScreenSaverPrice(): Observable<Stripe.Price> {
    return this._http
      .get(`/v1/display/screensaver/price`)
      .pipe(map((res) => res.body?.data));
  }
}
