import {
  CreateMediaDto,
  CursorPaginationResult,
  DisplayEntity,
  MediaEntity,
  MediaSeoMetadataEntity,
  SearchMediasQueryDto,
  UpdateMediaDto,
} from '@admefy/domain';
import { Injectable, TransferState, inject, makeStateKey } from '@angular/core';
import { serialize } from 'object-to-formdata';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { RestService } from './rest.service';

/**
 * Se encarga de hacer las acciones contra la API
 * */
@Injectable({ providedIn: 'root' })
export class MediaService {
  private readonly _http = inject(RestService);
  private readonly transferState: TransferState = inject(TransferState);

  public addMediaScore(
    mediaId: string,
    score: number,
  ): Observable<MediaEntity> {
    return this._http
      .patch(`/v1/media/${mediaId}/score`, {
        score,
      })
      .pipe(map((res) => res.body?.data));
  }

  public searchMedias(
    query: SearchMediasQueryDto,
    cache: boolean = false,
  ): Observable<CursorPaginationResult<MediaEntity>> {
    return this._http
      .get(`/v1/media`, query as unknown as Record<string, string>, cache)
      .pipe(map((res) => res.body?.data));
  }

  public searchMyMedias(
    params: Omit<SearchMediasQueryDto, 'owners'> = {},
  ): Observable<CursorPaginationResult<MediaEntity>> {
    return this._http
      .get(`/v1/media/me`, params as unknown as Record<string, string>)
      .pipe(map((res) => res.body?.data));
  }

  public getHighlightedMedias(): Observable<
    { media: MediaEntity; display: DisplayEntity }[]
  > {
    return this._http
      .get(`/v1/media/highlighted`)
      .pipe(map((res) => res.body?.data));
  }

  public getNextEvents(): Observable<
    { media: MediaEntity; display: DisplayEntity }[]
  > {
    return this._http
      .get(`/v1/media/next-events`)
      .pipe(map((res) => res.body?.data));
  }

  public getTopMedias(): Observable<MediaEntity[]> {
    return this._http.get(`/v1/media/top`).pipe(map((res) => res.body?.data));
  }

  public getMediasOnline(): Observable<MediaEntity[]> {
    return this._http
      .get(`/v1/media/online`)
      .pipe(map((res) => res.body?.data));
  }

  public cloneMedia(mediaId: string): Observable<MediaEntity> {
    return this._http
      .post(`/v1/media/${mediaId}/clone`, {})
      .pipe(map((res) => res.body?.data));
  }

  // Obtener un media por id del mismo
  public getMediaById(mediaId: string): Observable<MediaEntity> {
    return this._http
      .get(`/v1/media/${mediaId}`)
      .pipe(map((res) => res.body?.data));
  }

  public getMediaMetadata(mediaId: string): Observable<MediaSeoMetadataEntity> {
    const MEDIA_SEO = makeStateKey<MediaSeoMetadataEntity>(
      `media-seo-${mediaId}`,
    );

    if (this.transferState.hasKey(MEDIA_SEO)) {
      return of(
        this.transferState.get<MediaSeoMetadataEntity>(MEDIA_SEO, null),
      );
    }

    return this._http.get(`/v1/media/${mediaId}/metadata`).pipe(
      map((res) => res.body?.data),
      tap((metadata) => {
        this.transferState.set(MEDIA_SEO, metadata);
      }),
    );
  }

  public getMediaSocialDuration(url: string): Observable<number> {
    return this._http
      .post(`/v1/media/socialvideo`, {
        url,
      })
      .pipe(map((res) => res.body?.data));
  }

  public deleteMedia(mediaId: string): Observable<void> {
    return this._http
      .delete(`/v1/media/${mediaId}`)
      .pipe(map((res) => res.body?.data));
  }

  public publishMediaById(mediaId: string): Observable<void> {
    return this._http
      .put(`/v1/media/${mediaId}/publish`, {})
      .pipe(map((res) => res.body?.data));
  }

  public updateMedia(
    mediaId: string,
    body: UpdateMediaDto,
  ): Observable<MediaEntity> {
    return this._http
      .put(`/v1/media/${mediaId}`, body)
      .pipe(map((res) => res.body?.data));
  }

  public createMedia(body: CreateMediaDto): Observable<MediaEntity> {
    const data = serialize(body, {
      indices: true,
    });
    return this._http
      .postFormData(`/v1/media`, data)
      .pipe(map((res) => res.body?.data));
  }
}
