import { Location } from '@angular/common';
import { inject } from '@angular/core';
import { Routes } from '@angular/router';
import { provideTranslocoScope } from '@jsverse/transloco';
import { AppStore } from './core/+stores/app/app.store';
import { CanAccessGuard } from './core/guards/canaccess.guard';
import { CanSignUpGuard } from './core/guards/cansignup.guard';
import { IsEmailVerifiedGuard } from './core/guards/isEmailVerified.guard';
import { userResolver } from './core/resolvers/user.resolver';
import { TokenService } from './core/services/token.service';
import {
  datesResolver,
  displaysResolver,
} from './routes/creator/creator.resolvers';

export const loaderStaticLandingMainLangs = ['en', 'es'].reduce((acc, lang) => {
  acc[lang] = () => import(`../assets/i18n/landing-main/${lang}.json`);
  return acc;
}, {});

export const loaderStaticLandingStreamersLangs = ['en', 'es'].reduce(
  (acc, lang) => {
    acc[lang] = () => import(`../assets/i18n/landing-streamers/${lang}.json`);
    return acc;
  },
  {},
);

export const loaderStaticLandingAdvertisersLangs = ['en', 'es'].reduce(
  (acc, lang) => {
    acc[lang] = () => import(`../assets/i18n/landing-advertisers/${lang}.json`);
    return acc;
  },
  {},
);

export const loaderStaticLandingDigitalSignageLangs = ['en', 'es'].reduce(
  (acc, lang) => {
    acc[lang] = () =>
      import(`../assets/i18n/landing-digital-signage/${lang}.json`);
    return acc;
  },
  {},
);

export const loaderStaticDisplaysNetworksLangs = ['en', 'es'].reduce(
  (acc, lang) => {
    acc[lang] = () => import(`../assets/i18n/displays-networks/${lang}.json`);
    return acc;
  },
  {},
);

export const loaderStaticInfoLangs = ['en', 'es'].reduce((acc, lang) => {
  acc[lang] = () => import(`../assets/i18n/info/${lang}.json`);
  return acc;
}, {});

export const APP_ROUTES: Routes = [
  {
    path: 'login',
    loadComponent: () =>
      import('./routes/signin/signin.component').then((m) => m.SigninComponent),
  },
  {
    path: '',
    loadComponent: () =>
      import('./routes/landing-main/landing-main.component').then(
        (m) => m.LandingMainComponent,
      ),
    providers: [
      provideTranslocoScope({
        scope: 'landingMainScope',
        loader: loaderStaticLandingMainLangs,
      }),
    ],
    canMatch: [
      () => {
        const location = inject(Location);
        const tokenService = inject(TokenService);
        const appStore = inject(AppStore);

        const isValidToken = tokenService.get().isValid();

        if (isValidToken) {
          return false;
        }
        const path = location.path();

        if (path && path !== '/') {
          appStore.setRedirectUrl(path);
        }

        return true;
      },
    ],
  },
  // {
  //   path: 'info',
  //   loadComponent: () =>
  //     import('./routes/info/info.component').then((m) => m.InfoComponent),
  //   providers: [
  //     provideTranslocoScope({
  //       scope: 'infoScope',
  //       loader: loaderStaticInfoLangs,
  //     }),
  //   ],
  // },
  // {
  //   path: 'admefy-streamers',
  //   loadComponent: () =>
  //     import('./routes/landing-streamers/landing-streamers.component').then(
  //       (m) => m.LandingStreamersComponent,
  //     ),
  //   providers: [
  //     provideTranslocoScope({
  //       scope: 'landingStreamersScope',
  //       loader: loaderStaticLandingStreamersLangs,
  //     }),
  //   ],
  //   canMatch: [
  //     () => {
  //       const location = inject(Location);
  //       const tokenService = inject(TokenService);
  //       const appStore = inject(AppStore);

  //       const isValidToken = tokenService.get().isValid();

  //       if (isValidToken) {
  //         return false;
  //       }
  //       const path = location.path();

  //       if (path && path !== '/') {
  //         appStore.setRedirectUrl(path);
  //       }

  //       return true;
  //     },
  //   ],
  // },
  {
    path: 'admefy-digital-signage',
    loadComponent: () =>
      import(
        './routes/landing-digital-signage/landing-digital-signage.component'
      ).then((m) => m.LandingDigitalSignageComponent),
    providers: [
      provideTranslocoScope({
        scope: 'landingDigitalSignageScope',
        loader: loaderStaticLandingDigitalSignageLangs,
      }),
    ],
    canMatch: [
      () => {
        const location = inject(Location);
        const tokenService = inject(TokenService);
        const appStore = inject(AppStore);

        const isValidToken = tokenService.get().isValid();

        if (isValidToken) {
          return false;
        }
        const path = location.path();

        if (path && path !== '/') {
          appStore.setRedirectUrl(path);
        }

        return true;
      },
    ],
  },
  {
    path: 'admefy-displays-networks',
    loadComponent: () =>
      import('./routes/displays-networks/displays-networks.component').then(
        (m) => m.DisplaysNetworksComponent,
      ),
    providers: [
      provideTranslocoScope({
        scope: 'displaysNetworksScope',
        loader: loaderStaticDisplaysNetworksLangs,
      }),
    ],
    canMatch: [
      () => {
        const location = inject(Location);
        const tokenService = inject(TokenService);
        const appStore = inject(AppStore);

        const isValidToken = tokenService.get().isValid();

        if (isValidToken) {
          return false;
        }
        const path = location.path();

        if (path && path !== '/') {
          appStore.setRedirectUrl(path);
        }

        return true;
      },
    ],
  },
  {
    path: 'admefy-advertisers',
    loadComponent: () =>
      import('./routes/landing-advertisers/landing-advertisers.component').then(
        (m) => m.LandingAdvertisersComponent,
      ),
    providers: [
      provideTranslocoScope({
        scope: 'landingAdvertisersScope',
        loader: loaderStaticLandingAdvertisersLangs,
      }),
    ],
    canMatch: [
      () => {
        const location = inject(Location);
        const tokenService = inject(TokenService);
        const appStore = inject(AppStore);

        const isValidToken = tokenService.get().isValid();

        if (isValidToken) {
          return false;
        }
        const path = location.path();

        if (path && path !== '/') {
          appStore.setRedirectUrl(path);
        }

        return true;
      },
    ],
  },
  {
    path: '',
    redirectTo: '/creator/map-digital-signage',
    pathMatch: 'full',
  },
  // {
  //   path: 'stream',
  //   loadComponent: () =>
  //     import('./routes/streams/streams.component').then(
  //       (m) => m.StreamsComponent,
  //     ),
  //   data: {
  //     business: false,
  //   },
  // },
  // {
  //   path: 'business',
  //   loadComponent: () =>
  //     import('./routes/streams/streams.component').then(
  //       (m) => m.StreamsComponent,
  //     ),
  //   data: {
  //     business: true,
  //   },
  // },
  {
    path: 'partners',
    loadComponent: () =>
      import('./routes/partners/partners.component').then(
        (m) => m.PartnersComponent,
      ),
  },
  {
    path: 'manufacturers',
    loadComponent: () =>
      import('./routes/manufacturers/manufacturers.component').then(
        (m) => m.ManufacturersComponent,
      ),
  },
  {
    path: 'reset-devices',
    loadComponent: () =>
      import('./routes/reset-devices/reset-devices.component').then(
        (m) => m.ResetDevicesPageComponent,
      ),
    canActivateChild: [CanSignUpGuard],
  },
  {
    path: 'signup',
    loadComponent: () =>
      import('./routes/signup/signup.component').then((m) => m.SignUpComponent),
    canActivateChild: [CanSignUpGuard],
  },
  {
    path: 'start',
    loadComponent: () =>
      import('./routes/start/start.component').then((m) => m.StartComponent),
  },
  {
    path: 'downloads',
    loadComponent: () =>
      import('./routes/downloads/downloads.component').then(
        (m) => m.DownloadsComponent,
      ),
  },
  // {
  //   path: 'reels',
  //   loadComponent: () =>
  //     import('./routes/reels/reels.component').then((m) => m.ReelsComponent),
  //   canActivateChild: [CanAccessGuard],
  // },
  {
    path: 'checkout',
    loadComponent: () =>
      import('./routes/checkout/checkout.component').then(
        (m) => m.CheckoutComponent,
      ),
    canActivateChild: [CanAccessGuard],
  },
  {
    path: 'verify-email',
    loadComponent: () =>
      import('./routes/verify-email/verify-email.component').then(
        (m) => m.VerifyEmailComponent,
      ),
    canActivateChild: [IsEmailVerifiedGuard],
  },
  {
    path: 'membership',
    loadComponent: () =>
      import('./routes/membership/membership.component').then(
        (m) => m.MembershipComponent,
      ),
    canActivateChild: [CanSignUpGuard],
  },
  {
    path: 'passrestore',
    loadChildren: () =>
      import('./routes/passrestore/passrestore.routes').then(
        (m) => m.PASSRESTORE_ROUTES,
      ),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./routes/profile/profile.routes').then((m) => m.PROFILE_ROUTES),
    canActivateChild: [CanAccessGuard],
  },
  {
    path: 'legal',
    loadComponent: () =>
      import('./routes/legal/legal.component').then((m) => m.LegalComponent),
  },
  {
    path: 'conditions',
    loadComponent: () =>
      import('./routes/conditions/conditions.component').then(
        (m) => m.ConditionsComponent,
      ),
  },
  {
    path: 'staff',
    loadChildren: () =>
      import('./routes/staff/staff.routes').then((m) => m.STAFF_ROUTES),
  },
  {
    path: 'changelog',
    loadComponent: () =>
      import('./routes/changelog/changelog.component').then(
        (m) => m.ChangelogComponent,
      ),
  },
  {
    path: 'admefypay',
    loadChildren: () =>
      import('./routes/admefypay/admefypay.routes').then(
        (m) => m.ADMEFY_PAY_ROUTES,
      ),
    canActivateChild: [CanAccessGuard],
  },
  {
    path: 'contact',
    loadComponent: () =>
      import('./routes/contact/contact.component').then(
        (m) => m.ContactComponent,
      ),
  },
  {
    path: 'not-allowed',
    loadComponent: () =>
      import('./routes/not-allowed/not-allowed.component').then(
        (m) => m.NotAllowedComponent,
      ),
  },
  // {
  //   path: 'presentation',
  //   loadComponent: () =>
  //     import('./routes/creator/presentation/presentation.component').then(
  //       (m) => m.PresentationComponent,
  //     ),
  // },
  // {
  //   path: 'corporate',
  //   loadComponent: () =>
  //     import('./routes/corporate/corporate.component').then(
  //       (m) => m.CorporateComponent,
  //     ),
  // },
  // {
  //   path: 'landing',
  //   loadComponent: () =>
  //     import('./routes/landing/landing.component').then(
  //       (m) => m.LandingComponent,
  //     ),
  // },
  {
    path: 'creator/map-digital-signage',
    loadComponent: () =>
      import(
        './routes/creator/map-digital-signage/map-digital-signage.component'
      ).then((m) => m.MapDigitalSignageComponent),
    canActivate: [CanAccessGuard],
    resolve: {
      dates: datesResolver,
      displays: displaysResolver,
    },
  },
  {
    path: 'creator',
    loadChildren: () =>
      import('./routes/creator/creator.routes').then((m) => m.CREATOR_ROUTES),
    canActivateChild: [CanAccessGuard],
  },
  {
    path: 'privacy',
    loadComponent: () =>
      import('./routes/privacy/privacy.component').then(
        (m) => m.PrivacyComponent,
      ),
  },
  {
    path: 'cookies',
    loadComponent: () =>
      import('./routes/cookies/cookies.component').then(
        (m) => m.CookiesComponent,
      ),
  },
  // {
  //   path: 'admefy-mobile/:displayId',
  //   loadComponent: () =>
  //     import('./routes/displays-mobile-map/displays-mobile-map.component').then(
  //       (m) => m.DisplaysMobileMapComponent,
  //     ),
  // },
  {
    path: 'display',
    loadChildren: () =>
      import('./routes/displays/displays.routes').then(
        (m) => m.DISPLAYS_ROUTES,
      ),
    canActivateChild: [CanAccessGuard],
  },
  // {
  //   path: 'emit',
  //   loadChildren: () =>
  //     import('./routes/emit/emit.routes').then((m) => m.EMIT_ROUTES),
  //   canActivateChild: [CanAccessGuard],
  // },
  {
    path: 'post',
    loadChildren: () =>
      import('./routes/post/post.routes').then((m) => m.POST_ROUTES),
  },
  // {
  //   path: 'dvr',
  //   loadChildren: () =>
  //     import('./routes/dvr/dvr.routes').then((m) => m.DVR_ROUTES),
  //   canActivateChild: [CanAccessGuard],
  // },
  // {
  //   path: 'categories',
  //   loadChildren: () =>
  //     import('./routes/categories/categories.routes').then(
  //       (m) => m.CATEGORIES_ROUTES,
  //     ),
  // },
  {
    path: 'scan',
    pathMatch: 'full',
    redirectTo: '/creator/scanner',
  },
  {
    path: ':userId',
    resolve: {
      userInView: userResolver,
    },
    canActivateChild: [CanAccessGuard],
    loadChildren: () =>
      import('./routes/profile-public/profile-public-view.routes').then(
        (m) => m.PROFILE_PUBLIC_PROFILE_VIEW_ROUTES,
      ),
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];
