import { DisplayService } from '@/app/core/services/display.service';
import { MediaService } from '@/app/core/services/media.service';
import dayjs from '@/app/core/utils/dayjs';
import { DisplayFlattenEntity, MediaEntity } from '@admefy/domain';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { catchError, map, of } from 'rxjs';

export const displaysResolver: ResolveFn<DisplayFlattenEntity[]> = (
  route: ActivatedRouteSnapshot,
) => {
  const displaysIds = decodeURIComponent(route.queryParams['displays'] || '')
    .split(';')
    .filter(Boolean);

  if (!displaysIds.length) {
    return of([]);
  }

  const displayService = inject(DisplayService);

  return displayService
    .searchDisplays({
      displayIds: displaysIds.join(','),
      limit: displaysIds.length,
      flatten: true,
    })
    .pipe(
      map(({ results }) => results),
      catchError(() => of([])),
    );
};

export const mediaResolver: ResolveFn<MediaEntity> = (
  route: ActivatedRouteSnapshot,
) => {
  const mediaId = route.queryParams['mediaId'];

  if (!mediaId) {
    return of(null);
  }

  const mediaService = inject(MediaService);

  return mediaService.getMediaById(mediaId).pipe(catchError(() => of(null)));
};

export const datesResolver: ResolveFn<string[]> = (
  route: ActivatedRouteSnapshot,
) => {
  const dates = route.queryParamMap.get('dates') || '';
  const today = dayjs();

  if (dates) {
    return decodeURIComponent(dates)
      .split(';')
      .flatMap((date) => {
        const parsedDate = dayjs(date, 'DD-MM-YYYY');
        const isValid =
          parsedDate.isValid() && parsedDate.isAfter(today, 'day');
        return isValid ? [parsedDate.format('YYYY-MM-DD')] : [];
      });
  }

  return [];
};
