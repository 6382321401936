import { isPlatformBrowser } from '@angular/common';
import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import Cache from './cache';
import { GlobalStateService } from './globalstate.service';
import { TokenService } from './token.service';

export type HttpParamsType =
  | HttpParams
  | {
      [param: string]:
        | string
        | number
        | boolean
        | ReadonlyArray<string | number | boolean>;
    };

/**
 * Se encarca de hacer las acciones contra la API
 * */
@Injectable({ providedIn: 'root' })
export class RestService {
  public http = inject(HttpClient);
  private tokenService = inject(TokenService);
  private gs = inject(GlobalStateService);
  private platformId = inject<object>(PLATFORM_ID);
  cache = new Cache();

  apiUrl: string = environment.apiUrl;

  private DISABLED_CACHE = false;

  /**
   * Cabeceras
   *
   * @returns {HttpHeaders}
   */
  private getHeaders(): HttpHeaders {
    const lang = this.gs.get('lang') || 'es';

    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': lang,
      'x-api-version': 'v2',
    };

    const token = this.tokenService.get();
    if (token.isValid()) {
      headersConfig['Authorization'] = 'Bearer ' + token.getValue();
    }

    return new HttpHeaders(headersConfig);
  }

  /**
   * Handler de error
   *
   * @returns {any}
   * @param res
   */
  private formatErrors(res: any) {
    const error: any = res || res?.body || {};

    if (!isPlatformBrowser(this.platformId)) {
      console.error(error);
      return of<any>({});
    }

    return throwError(() => error);
  }

  /**
   * Envia una petición post
   *
   * @param path
   * @param body
   * @param params
   * */
  post(
    path: string,
    body: any,
    params: HttpParamsType = new HttpParams(),
  ): Observable<any> {
    return this.http
      .post(`${this.apiUrl}${path}`, body ?? {}, {
        observe: 'response',
        headers: this.getHeaders(),
        params,
      })
      .pipe(
        catchError((e: any) => this.formatErrors(e)),
        tap(() => this.cache.clear()),
      );
  }

  /**
   * Envia una petición post
   *
   * @param path
   * @param body
   * */
  put(
    path: string,
    body: any,
    params: HttpParamsType = new HttpParams(),
  ): Observable<any> {
    return this.http
      .put(`${this.apiUrl}${path}`, body, {
        observe: 'response',
        headers: this.getHeaders(),
        params,
      })
      .pipe(
        catchError(this.formatErrors.bind(this)),
        tap(() => this.cache.clear()),
      );
  }

  /**
   * Envia una petición post
   *
   * @param path
   * @param body
   * */
  patch(
    path: string,
    body: any,
    params: HttpParamsType = new HttpParams(),
  ): Observable<any> {
    return this.http
      .patch(`${this.apiUrl}${path}`, body, {
        observe: 'response',
        headers: this.getHeaders(),
        params,
      })
      .pipe(
        catchError(this.formatErrors.bind(this)),
        tap(() => this.cache.clear()),
      );
  }

  /**
   * Envia una petición get
   *
   * @param path
   * @param params
   * @param force Sin cache
   * */
  get(
    path: string,
    params: HttpParamsType = new HttpParams(),
    cache: boolean | number = false,
    url = this.apiUrl,
  ): Observable<any> {
    if (!params) {
      params = new HttpParams();
    }

    // Base64
    let encodedParams = '';
    if (Object.keys(params).length) {
      const jsonParams = JSON.stringify(params);
      encodedParams = btoa(jsonParams);
    }

    const key = [path, encodedParams].join('/');
    const resC: HttpResponse<any> = this.DISABLED_CACHE
      ? null
      : this.cache.get(key);

    if (resC && cache) {
      return of(resC);
    }

    return this.http
      .get(`${url || this.apiUrl}${path}`, {
        observe: 'response',
        headers: this.getHeaders(),
        params,
      })
      .pipe(
        catchError(this.formatErrors.bind(this)),
        tap((res: HttpResponse<any>) => {
          if (cache !== false) {
            this.cache.set(key, res, cache === true ? 0 : Number(cache));
          }
        }),
      );
  }

  /**
   * Envia una petición get
   *
   * @param path
   * @param params
   * */
  delete(
    path: string,
    params: HttpParamsType = new HttpParams(),
  ): Observable<any> {
    const headers = this.getHeaders();
    return this.http
      .delete(`${this.apiUrl}${path}`, {
        observe: 'response',
        headers,
        params,
        body: {},
      })
      .pipe(
        catchError(this.formatErrors.bind(this)),
        tap(() => this.cache.clear()),
      );
  }

  /**
   * Envia una petición para borrar achivo.
   *
   * @param path
   * @param body
   * */
  deleteFile(path: string, body): Observable<any> {
    return this.http
      .request('delete', `${environment.apiUploadUrl}${path}`, {
        observe: 'response',
        headers: this.getHeaders(),
        body,
      })
      .pipe(
        catchError(this.formatErrors.bind(this)),
        tap(() => this.cache.clear()),
      );
  }

  /**
   * Envia una petición post
   *
   * @param path
   * @param body
   * @param options
   * */
  upload(path: string, body: any, options: any = {}): Observable<any> {
    let headers = this.getHeaders();
    headers = headers.delete('Content-Type');
    headers = headers.delete('Accept');

    return this.http
      .post(
        `${environment.apiUploadUrl}${path}`,
        body,
        Object.assign(options, {
          reportProgress: true,
          observe: 'events',
          headers,
        }),
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage;
          if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
          } else {
            // Get server-side error
            errorMessage = error;
          }
          return throwError(() => errorMessage);
        }),
      );
  }

  /**
   * Envia una petición put
   *
   * @param path
   * @param body
   * @param options
   * */
  putFormData(
    path: string,
    body: unknown,
    options: {
      context?: HttpContext;
      params?:
        | HttpParams
        | Record<
            string,
            string | number | boolean | ReadonlyArray<string | number | boolean>
          >;
      reportProgress?: boolean;
      withCredentials?: boolean;
      transferCache?:
        | {
            includeHeaders?: string[];
          }
        | boolean;
    } = {},
  ): Observable<any> {
    let headers = this.getHeaders();
    headers = headers.delete('Content-Type');
    headers = headers.delete('Accept');

    return this.http
      .put(`${this.apiUrl}${path}`, body, {
        ...options,
        observe: 'response',
        headers,
      })
      .pipe(catchError(this.formatErrors.bind(this)));
  }

  /**
   * Envia una petición post
   *
   * @param path
   * @param body
   * @param options
   * */
  postFormData(
    path: string,
    body: FormData,
    options: {
      context?: HttpContext;
      params?:
        | HttpParams
        | Record<
            string,
            string | number | boolean | ReadonlyArray<string | number | boolean>
          >;
      reportProgress?: boolean;
      withCredentials?: boolean;
      transferCache?:
        | {
            includeHeaders?: string[];
          }
        | boolean;
    } = {},
  ): Observable<any> {
    let headers = this.getHeaders();
    headers = headers.delete('Content-Type');
    headers = headers.delete('Accept');

    return this.http
      .post(`${this.apiUrl}${path}`, body, {
        ...options,
        observe: 'response',
        headers,
      })
      .pipe(catchError(this.formatErrors.bind(this)));
  }
}
